<template>
  <div>
    <el-form :inline="true" :model="queryForm" size="small">
      <el-form-item label="园区">
        <el-select v-model="queryForm.home_area_id" clearable placeholder="请选择">
          <el-option v-for="item in allParkData" :key="item.id" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button @click="handleSearch" type="primary">查询</el-button>
        <el-button @click="handleAdd" type="primary" size="small">新增</el-button>
      </el-form-item>
    </el-form>

    <el-table :data="tableData" v-loading="tableLoading" border stripe
      :header-cell-style="{background:'#E4E7ED',color: '#606266'}" size="small" style="width: 100%;" max-height="500px">
      <el-table-column label="#" type="index"></el-table-column>
      <el-table-column label="楼栋名称" v-slot="{ row }">
        {{ `${row.area.name} / ${row.name}` }}
      </el-table-column>
      <el-table-column label="是否启用" v-slot="{ row }">
        <el-tag v-if="row.status === 1" type="success" size="small">启用</el-tag>
        <el-tag v-else type="info" size="small">禁用</el-tag>
      </el-table-column>
      <el-table-column label="操作">
        <template v-slot="{ row }">
          <el-link @click="handleEdit(row)" type="primary" class="margin-r-10">编辑</el-link>
          <el-popconfirm @confirm="handleDel(row.id)" title="确定删除吗？">
            <el-link slot="reference" type="danger">删除</el-link>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <div class="margin-t-10 flex">
      <el-pagination background layout="prev, pager, next, total, jumper" :total="total"
        :current-page.sync="queryForm.page" :page-size="pageSize" @current-change="getBuildingData">
      </el-pagination>
      <el-button size="small">确定</el-button>
    </div>

    <!-- 新增、编辑弹窗 -->
    <el-dialog :title="dialogTitle" :visible.sync="showDialog" :close-on-click-modal="false" @close="closeDialog"
      width="500px">
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-form-item label="楼栋名称" prop="name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="园区" prop="home_area_id">
          <el-select v-model="form.home_area_id" clearable placeholder="请选择" class="el-input_inner--rewrite">
            <el-option v-for="item in allParkData" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否启用">
          <el-switch v-model="form.status" :active-value="1" :inactive-value="0" active-color="#13ce66"
            inactive-color="#ff4949" active-text="启用" inactive-text="禁用">
          </el-switch>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="closeDialog">取消</el-button>
        <el-button @click="handleSubmit" :loading="formSubmitting" type="primary">提交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import {
    getAllParkDataAPI,
    saveBuildingAPI,
    delBuildingAPI,
    getBuildingDataAPI,
  } from '@/api/base-data.js'

  export default {
    name: 'Building',
    data() {
      return {
        allParkData: [],

        // 表格相关
        queryForm: {
          page: 1,
          home_area_id: '',
        },
        tableData: [],
        total: 0,
        pageSize: 0,
        tableLoading: false,
        curRowData: null,

        // 表单相关
        showDialog: false,
        dialogTitle: '',
        formSubmitting: false,
        form: {
          name: '',
          home_area_id: '',
          status: 1
        },
        rules: {
          name: [{
            required: true,
            message: '楼栋名称不能为空',
            trigger: 'blur'
          }],
          home_area_id: [{
            required: true,
            message: '园区不能为空',
            trigger: 'change'
          }]
        }
      }
    },
    created() {
      getAllParkDataAPI().then(res => this.allParkData = res)
      this.getBuildingData()
    },
    methods: {
      // 新增按钮
      handleAdd() {
        this.showDialog = true
        this.dialogTitle = '新增'
      },
      // 查询按钮
      handleSearch() {
        this.queryForm.page = 1
        this.getBuildingData()
      },
      closeDialog() {
        this.$refs.form.resetFields()
        this.showDialog = false
      },
      // 编辑按钮
      handleEdit(row) {
        this.showDialog = true
        this.dialogTitle = '编辑'
        this.curRowData = JSON.parse(JSON.stringify(row))

        for (let key in this.form) {
          this.form[key] = this.curRowData[key]
        }
      },
      // 删除按钮
      handleDel(id) {
        delBuildingAPI({
          id,
        }).then(res => {
          if (res.code === 0) {
            this.$message.success(res.msg)
            this.getBuildingData()
          }
        })
      },
      // 提交按钮
      handleSubmit() {
        this.$refs.form.validate(isValid => {
          if (!isValid) return

          this.formSubmitting = true
          const copyForm = JSON.parse(JSON.stringify(this.form))

          if (this.dialogTitle === '编辑') copyForm.id = this.curRowData.id
          // 提交
          saveBuildingAPI(copyForm).then(res => {
            this.$message.success(res.msg)
            this.closeDialog()
            this.getBuildingData()
          }).finally(() => this.formSubmitting = false)
        })
      },
      // 获取楼栋数据
      getBuildingData() {
        this.tableLoading = true

        getBuildingDataAPI(this.queryForm).then(res => {
          this.tableData = res.data
          this.total = res.total
          this.pageSize = res.per_page
          this.tableLoading = false
        })
      }
    }
  }
</script>

<style scoped>
</style>