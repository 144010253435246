import { render, staticRenderFns } from "./building.vue?vue&type=template&id=dc74a130&scoped=true&"
import script from "./building.vue?vue&type=script&lang=js&"
export * from "./building.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dc74a130",
  null
  
)

export default component.exports